<template>
  <div class="mask" v-if="visible">
    <div class="dialog">
      <div class="banner" v-if="!isMobile">
        <div class="logo">WELCOME</div>
      </div>
      <div class="job-selector">
        <i class="iconfont icon-close" @click="setVisible(false)"></i>
        <div class="job-selector-title">{{ L("请选择您关注的专业") }}</div>
        <div class="job-selector-list">
          <div class="job-selector-button-group">
            <div
              class="job-selector-button"
              v-for="item in typeList"
              :key="item"
              @click="onSelect(item)"
              :class="hasElement(item) ? 'active' : ''"
            >
              {{ L(item) }}
            </div>
          </div>
        </div>
        <button class="confirm-button" @click="confirm">{{ L("确认") }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import mobile from "@/mixins/mobile";
import { mapMutations, mapState } from "vuex";
export default {
  mixins: [mobile],
  props: {
    configInfo: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    typeList() {
      return typeof this.configInfo.MAJOR_TYPE === "string"
        ? this.configInfo.MAJOR_TYPE.split(",")
        : [];
    },

    ...mapState("major", ["visible"]),
  },

  data() {
    return {
      selected: [],
    };
  },

  methods: {
    ...mapMutations("major", ["setVisible", "setSelected"]),

    onSelect(item) {
      const index = this.selected.indexOf(item);
      if (index === -1) {
        this.selected.push(item);
      } else {
        this.selected.splice(index, 1);
      }
    },

    hasElement(element) {
      return this.selected.indexOf(element) !== -1;
    },

    confirm() {
      if (this.selected.length === 0) {
        this.$message.error("请选择专业");
        return;
      }
      window.localStorage.setItem(
        "selectedMajor",
        JSON.stringify(this.selected)
      );
      this.setSelected(this.selected);
      this.setVisible(false);
    },
  },
};
</script>


<style lang="less" scoped>
@media screen and (max-width: 767px) {
  .mask .dialog {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90vw;
    height: 50vh;
    transform: translateX(-50%) translateY(-50%);
    display: flex;
    flex-direction: column;
    background: #ffffff;
    border-radius: 15px;
    color: #333;
  }

  .mask .dialog .job-selector {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .mask .dialog .job-selector .icon-close {
    position: absolute;
    top: 17px;
    right: 17px;
    font-size: 22px;
    color: #999;
    cursor: pointer;
  }
  .mask .dialog .job-selector .job-selector-title {
    margin-top: 18px;
    margin-bottom: 16px;
    font-size: 18px;
    font-weight: 400;
    color: #323232;
    text-align: center;
  }
  .mask .dialog .job-selector .job-selector-list {
    display: flex;
    padding-left: 30px;
    margin-bottom: 60px;
  }
  .mask .dialog .job-selector .job-selector-label {
    font-size: 19px;
    color: #323232;
    margin-right: 25px;
    flex-shrink: 0;
  }
  .mask .dialog .job-selector .job-selector-button-group {
    display: flex;
    flex-wrap: wrap;
  }
  .mask .dialog .job-selector .job-selector-button {
    height: 36px;
    line-height: 36px;
    font-size: 15px;
    color: #323232;
    padding: 0 16px;
    // max-width: 154px;
    background: #f1f1f1;
    border-radius: 4px;
    margin-right: 8px;
    margin-bottom: 8px;
    cursor: pointer;
  }
  .mask .dialog .job-selector .active {
    background: #80e1e1;
    box-shadow: 0px 10px 15px rgba(150, 228, 228, 0.52);
    opacity: 0.8;
    border-radius: 4px;
    color: #fff;
  }
  .mask .dialog .job-selector .confirm-button {
    width: 90%;
    margin: 0 auto;
    height: 48px;
    background: #004BB0;
    font-size: 22px;
    font-weight: 400;
    color: #ffffff;
    margin: 0 auto;
    cursor: pointer;
  }
}
</style>
