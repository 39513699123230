<template>
  <div>
    <img class="company-header-image" src="@/assets/news-title.png" />

    <div class="news-wrapper">
      <div>
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 16px;
          "
        >
          <div class="news-title">
            {{ L("视频") }}
          </div>
          <div
            @click="openVideoMore"
            style="color: #999; font-size: 14px; cursor: pointer"
          >
            更多 &gt;
          </div>
        </div>
        <div class="videos" v-if="videos.length > 0">
          <img
            style="cursor: pointer"
            v-for="item in videos"
            :key="item.uuid"
            :src="item.face_file"
            alt=""
            @click="openVideo(item)"
          />
        </div>
      </div>

      <div style="display: flex; flex-direction: column">
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 16px;
          "
        >
          <div class="news-title">
            {{ L("新闻") }}
          </div>
          <div
            @click="openNewsMore"
            style="color: #999; font-size: 14px; cursor: pointer"
          >
            更多 &gt;
          </div>
        </div>

        <div class="list" v-if="list.length > 0">
          <div
            class="item"
            v-for="(item, index) in list"
            :key="index"
            @click="route(item)"
          >
            <div class="title">{{ L(item.title) }}</div>
            <!-- <div class="date">
                {{ item.create_at | timeTrans("yyyy-MM-dd") }}
              </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchList } from "@/api/news";
export default {
  data() {
    return {
      current: 1,
      pageSize: 10,
      list: [],
      videos: [],
    };
  },

  mounted() {
    this.getList();
    this.getVideo();
  },
  methods: {
    getList() {
      const { current, pageSize } = this;

      fetchList({
        page: current,
        rows: pageSize,
        category: "NEWS",
      }).then((res) => {
        if (Array.isArray(res.list)) {
          this.list = res.list;
        }
      });
    },

    getVideo() {
      fetchList({
        page: 1,
        rows: 4,
        category: "VIDEO",
      }).then((res) => {
        if (Array.isArray(res.list)) {
          this.videos = res.list;
        }
      });
    },

    route(it) {
      this.$router.push("/news/detail?code=" + it.code);
    },
    openVideo(it) {
      this.$router.push("/video/detail?code=" + it.code);
    },

    openVideoMore() {
      this.$router.push("/video");
    },

    openNewsMore() {
      this.$router.push("/news");
    },
  },
};
</script>

<style lang="less" scoped>
.company-header-image {
  display: block;
  margin: 78px auto 48px;
  width: 254px;
}

.news-wrapper {
  display: flex;
  gap: 40px;
  padding-bottom: 40px;
}

.news-title {
  font-size: 24px;
  font-weight: bold;
  color: #20317f;
}
.news {
  display: flex;
}
.news > img {
  height: 200px;
  margin-right: 24px;
  cursor: pointer;
}
.news .list {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.item .title {
  max-width: 650px;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.item .date {
  color: #999;
  flex-shrink: 0;
}

.videos {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}
.videos img {
  width: 100%;
  height: 160px;
  object-fit: cover;
  display: block;
}

.list {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  .news-wrapper {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding-bottom: 40px;
  }
  .videos {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 16px;
  }

  .list {
    gap: 1em;
  }
  .news {
    display: flex;
    flex-direction: column;
  }
  .news > img {
    margin-right: 0;
    margin-bottom: 24px;
  }
  .item .title {
    max-width: 90vw;
  }
}
</style>