<template>
  <div class="swiper">
    <img :src="configInfo.SITE_BANNER" alt="" />
  </div>
</template>

<script>
// import Swiper from "swiper";
// import "swiper/swiper.min.css";

export default {
  props: {
    configInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  // mounted() {
  //   this.swiper = new Swiper(".swiper", {
  //     grabCursor: true,
  //     speed: 300,
  //     autoplay: {
  //       delay: 3000,
  //     },
  //     pagination: {
  //       el: ".swiper-pagination",
  //       clickable: true,
  //     },
  //   });
  // },
};
</script>


<style lang="less" scoped>
.swiper {
  // height: 45.5vw;
  img {
    display: block;
    width: 100%;
    // height: 100%;
    // object-fit: cover;
  }
}
</style>