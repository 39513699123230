<template>
  <div class="container search-panel">
    <div class="search">
      <div class="selector">
        <div style="display: flex; align-items: center" @click="categorySelectorVisible = !categorySelectorVisible">
          <span class="text">{{
            searchCategory ? L(searchCategory) : L("岗位专业")
          }}</span>
          <div style="transition: all 0.3s" :class="categorySelectorVisible ? 'rotate' : ''">
            <i class="iconfont icon-arrow-down-bold"></i>
          </div>
        </div>

        <div class="job-category" v-if="categorySelectorVisible">
          <!-- <div
            :class="searchCategory == '全部' ? 'active' : ''"
            @click="
              searchCategory = '全部';
              categorySelectorVisible = false;
            "
          >
            {{ L("全部") }}
          </div> -->
          <div v-for="item in typeList" :key="item" :class="searchCategory === item ? 'active' : ''" @click="
            searchCategory = item;
          categorySelectorVisible = false;
                        ">
            {{ L(item) }}
          </div>
        </div>
      </div>
      <input type="text" :placeholder="L('请输入职位、公司或关键词')" v-model="searchValue" @keydown.enter="search" />
      <img src="@/assets/icon-search.png" alt="" @click="search" />
    </div>
    <div class="hot-search">
      <span>{{ L("热门搜索") }}：</span>
      <!-- <template  > -->
        <router-link v-for="(it, index) in hotSearch" :key="index" :to="`/search?keyword=${it}`">{{
          it
        }}</router-link>
      <!-- </template> -->
    </div>
    <!-- <img
      class="decoration-bg-1"
      v-if="!isMobile"
      src="@/assets/bg3.png"
      alt=""
    /> -->
  </div>
</template>

<script>
import mobile from "@/mixins/mobile";
export default {
  mixins: [mobile],

  props: {
    configInfo: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      searchValue: "",
      searchCategory: "",
      categorySelectorVisible: false,
    };
  },

  computed: {
    hotSearch() {
      return typeof this.configInfo.HOT_SEARCH === "string"
        ? this.configInfo.HOT_SEARCH.split(",")
        : [];
    },

    typeList() {
      return typeof this.configInfo.MAJOR_TYPE === "string"
        ? this.configInfo.MAJOR_TYPE.split(",")
        : [];
    },
  },

  methods: {
    search() {
      if (!this.searchValue) {
        // this.$message.error("请输入职位、公司或关键词");
        // return;
        this.$router.push(`/search`);
      } else {
        this.$router.push(
          `/search?keyword=${this.searchValue}&major=${this.searchCategory}`
        );
      }
    },
  },
};
</script>

<style lang="less" scoped>
.search-panel {
  position: relative;
}

// .decoration-bg-1 {
//   position: absolute;
//   top: 0;
//   right: -150px;
//   width: 100px;
// }

.search {
  margin-top: 40px;
  margin-bottom: 15px;
  padding: 6px;
  display: flex;
  background: #ffffff;
  border: 2px solid rgba(0, 0, 0, 0.0784313725490196);
  border-radius: 5px;
}

.search .selector {
  flex-shrink: 0;
  padding-left: 6px;
  margin-right: 62px;
  display: flex;
  align-items: center;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
  cursor: pointer;
  position: relative;
}

.search .selector .text {
  margin-right: 24px;
}

.search .selector .iconfont {
  color: rgba(0, 0, 0, 0.3);
}

.search .selector .rotate {
  transform: rotate(-180deg);
}

.search .selector::after {
  content: "";
  position: absolute;
  top: 30%;
  bottom: 30%;
  right: -31px;
  width: 1.5px;
  background-color: rgba(0, 0, 0, 0.3);
}

.search .selector .job-category {
  position: absolute;
  z-index: 99;
  top: 44px;
  left: 0;
  width: 150px;
  background-color: #fff;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
  padding: 12px;
}

.search .selector .job-category>div {
  font-size: 13px;
  padding: 4px;
}

.search .selector .job-category>div:hover {
  background-color: rgba(0, 0, 0, 0.03);
  color: #000;
}

.search .selector .job-category .active {
  background-color: #004BB0;
  color: #fff;
}

.search input {
  flex: 1;
  border-width: 0;
  outline: none;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  opacity: 0.8;
}

.search input::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  opacity: 0.2;
}

.search img {
  height: 35px;
  width: 35px;
  cursor: pointer;
}

.hot-search {
  margin-bottom: 58px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
}

.hot-search a {
  display: inline-block;
  margin-right: 20px;
  color: #004BB0;
}

@media screen and (max-width: 767px) {
  .search-panel {
    // overflow: hidden;
  }

  .search .selector {
    font-size: 14px;
    flex-shrink: 0;
    margin-right: 6px;
  }

  .search .selector .text {
    margin-right: 4px;
  }

  .search .selector::after {
    content: "";
    width: 0;
  }
}
</style>