<template>
  <div>
    <img class="company-header-image" src="@/assets/company-list.png" />

    <div class="company-switch">
      <div
        @click="changeCategory('推荐公司')"
        :class="activeCompanyCategory === '推荐公司' ? 'active' : 'gray'"
      >
        {{ L("推荐公司") }}
      </div>
      <div
        @click="changeCategory('热门公司')"
        :class="activeCompanyCategory === '热门公司' ? 'active' : 'gray'"
      >
        {{ L("热门公司") }}
      </div>
    </div>

    <div class="company-list">
      <div
        class="company-item"
        v-for="it in renderList"
        :key="it.id"
        @click="openCompany(it)"
      >
        <img
          class="company-logo"
          v-if="it.logo_img"
          :src="it.logo_img"
          alt=""
        />
        <div v-else class="company-logo">
          <span v-text="L(it.short_name)"></span>
        </div>
        <div class="company-title">{{ L(it.name) }}</div>
        <!-- <div style="margin-bottom: 10px; font-size: 12px" class="gray">
          {{ L(it.city) }} · {{ L(it.area) }}
        </div> -->
        <div class="company-business">{{ L(it.business) }}</div>
        <div class="company-info">
          <div class="company-info-item">
            <div class="value">{{ L(it.type) }}</div>
            <div>{{ L("企业类型") }}</div>
          </div>
          <div class="company-info-item">
            <div class="value">{{ L(it.area) }}</div>
            <div>{{ L("所在区域") }}</div>
          </div>
          <div class="company-info-item">
            <div class="value">{{ it.job_num }}</div>
            <div>{{ L("招聘职位") }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="more-company" @click="loadMore">
      {{ L("更多公司") }}
    </div>
  </div>
</template>

<script>
import { fetchList } from "@/api/company";

export default {
  data() {
    return {
      activeCompanyCategory: "热门公司",

      current: 1,
      pageSize: 12,

      renderIndex: 1,
      topList: [],
      hotList: [],
    };
  },

  computed: {
    renderList() {
      let arr = this.topList;
      if (this.activeCompanyCategory === "热门公司") {
        arr = this.hotList;
      }
      return arr.filter((item, index) => index < this.renderIndex * 12);
    },
  },

  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      const { current, pageSize } = this;

      fetchList({
        page: current,
        rows: pageSize,
      }).then((res) => {
        this.loading = false;
        if (Array.isArray(res.list)) {
          this.topList = Object.freeze(
            [...res.list].sort((x, y) => {
              const top1 = parseInt(x.top);
              const top2 = parseInt(y.top);
              if (top1 > top2) {
                return 1;
              } else if (top1 < top2) {
                return -1;
              } else {
                return 0;
              }
            })
          );
          this.hotList = Object.freeze(
            res.list.sort((x, y) => {
              const views1 = parseInt(x.views);
              const views2 = parseInt(y.views);
              if (views1 > views2) {
                return 1;
              } else if (views1 < views2) {
                return -1;
              } else {
                return 0;
              }
            })
          );
        }
      });
    },

    loadMore() {
      // this.renderIndex++;
      this.$router.push("/search");
    },
    changeCategory(value) {
      this.activeCompanyCategory = value;
      this.renderIndex = 1;
    },

    openCompany(item) {
      this.$router.push("/company-detail?uuid=" + item.uuid);
    },
  },
};
</script>


<style lang="less" scoped>
.company-header-image {
  display: block;
  margin: 78px auto 48px;
  width: 254px;
}

.company-switch {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 36px;
}
.company-switch > div {
  font-size: 18px;
}
.company-switch > div {
  margin-right: 65px;
  cursor: pointer;
}
.company-switch > div:last-child {
  margin-right: 0;
}
.company-switch .active {
  color: #20317f;
  position: relative;
}
.company-switch .active::after {
  content: "";
  position: absolute;
  bottom: -12px;
  left: 50%;
  height: 0;
  width: 0;
  transform: translateX(-50%);
  border: 10px solid #20317f;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
}

.company-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
}
.company-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-size: contain;
  background-repeat: no-repeat;
  padding-top: 43px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  transition: all 0.3s;
  cursor: pointer;
  background-image: url("https://s.upapi.cn/2023/04/11/8fb60f71a9e64f5aa6b1e5ad61fc8128/bg.png");
}
.company-item:hover {
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
}

.company-item .company-logo {
  width: 67px;
  margin-bottom: 16px;
  width: 67px;
  height: 67px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-sizing: border-box;
  background-color: #004BB0;
  color: #fff;
  letter-spacing: 0.1em;
}

.company-item .company-title {
  font-size: 16px;
  color: #004BB0;
  margin-bottom: 0px;
  padding: 0 3px 0 3px;
  height: 45px;
}

.company-item .company-business {
  font-size: 13px;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  margin: 0 10px 20px 10px;
  height: 75px;
}

.company-item .company-info {
  display: flex;
  padding-bottom: 24px;
}
.company-item .company-info-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  position: relative;
  margin-right: 40px;
}
.company-item .company-info-item::after {
  content: "";
  position: absolute;
  top: 20%;
  bottom: 60%;
  right: -20px;
  width: 1px;
  background-color: #000;
}
.company-item .company-info-item:last-child {
  margin-right: 0;
}
.company-item .company-info-item:last-child::after {
  width: 0;
}
.company-item .company-info-item .value {
  font-size: 20px;
  color: #fabb1c;
  margin-bottom: 8px;
}

.more-company {
  margin: 40px auto 40px;
  width: 385px;
  height: 53px;
  border: 2px solid #20317f;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #20317f;
  cursor: pointer;
}
.more-company:hover {
  background-color: #20317f;
  color: #fff;
}

@media screen and (max-width: 767px) {
  .company-list {
    grid-template-columns: repeat(1, 1fr);
  }
  .company-item .company-business {
    font-size: 13px;
    margin: 10px;
  }

  .more-company {
    margin: 24px auto 66px;
    width: 80vw;
    height: 40px;
    font-size: 18px;
  }
}
</style>